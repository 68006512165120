<template>
  <div>
    <Row :gutter="16" style="margin-bottom: 24px">
      <Col span="6">
        <div>
          <Input placeholder="请输入公告标题" v-model="noticePrams.title"/>
        </div>
      </Col>
      <Col span="4">
        <div>
          <DatePicker v-model="noticePrams.created_at" placeholder="时间查询" style="width: 100%;"></DatePicker>
        </div>
      </Col>
      <Col span="6">
        <div>
          <Button type="primary" icon="ios-search-outline" @click="searchList">搜索</Button>
        </div>
      </Col>
    </Row>
    <Row :gutter="16" style="margin-bottom: 24px">
      <Col span="6">
        <div>
          <Button icon="md-add" type="primary" v-if="jurisdictionList.indexOf('admin-notice-add') !== -1" @click="addNotice('add')">新增公告</Button>
        </div>
      </Col>
    </Row>
    <Table border :columns="platformColumns" :data="data6">
      <template slot-scope="{ row, index }" slot="operation">
        <Button v-if="jurisdictionList.indexOf('admin-notice-update') !== -1" type="primary" size="small" style="margin-right: 5px" @click="show(index,'edit',row.id)">编辑</Button>
        <Button v-if="jurisdictionList.indexOf('admin-notice-del') !== -1" type="error" size="small" @click="removeNotice(row.id)">删除</Button>
      </template>
      <template slot-scope="{row} " slot="num">
        <div @click="seeTable(row)">
          <Button type="success" size="small">{{ row.quantity_seen }}</Button>
        </div>
      </template>
      <template slot-scope="{row} " slot="noticeType">
        <div>{{ row.notice_type === 1 ? '紧急公告' : row.notice_type === 2 ? '重要公告' : '普通' }}</div>
      </template>
      <template slot-scope="{row} " slot="userType">
        <div>{{ row.user_type === 1 ? '代理商公告' : row.user_type === 2 ? '平台用户公告' : '全部' }}</div>
      </template>
    </Table>
    <br/>
    <Page :total="total" :current="noticePrams.page" style="text-align:right" show-elevator show-total @on-change="pageChange"
          :page-size="noticePrams.limit"/>

    <Modal
        :title="formTitle"
        v-model="showModel"
        width="600"
        :mask-closable="false"
        @on-ok="submit"
        :footer-hide="true"
        @on-cancel="cancel"
        class-name="vertical-center-modal">
      <Form :model="formItem" ref="formItem" :rules="ruleValidate" :label-width="120" style="width: 500px">
        <FormItem label="公告标题" prop="title">
          <Input v-model="formItem.title" placeholder="请输入公告标题"></Input>
        </FormItem>
<!--        <FormItem label="公告平台" prop="store_id">-->
<!--          <Select v-model="formItem.store_id" placeholder="请选择公告平台">-->
<!--            <Option :value="item.id" v-for="(item,index) in unList" :key="index">{{ item.un_name }}</Option>-->
<!--          </Select>-->
<!--        </FormItem>-->
        <FormItem label="公告用户类型" prop="user_type">
          <Select v-model="formItem.user_type" placeholder="请选择公告类型">
            <Option value="1">代理商公告</Option>
            <Option value="2">平台用户公告</Option>
            <Option value="0">全部</Option>
          </Select>
        </FormItem>
        <FormItem label="公告类型" prop="notice_type">
          <Select v-model="formItem.notice_type" placeholder="请选择公告类型">
            <Option value="1">紧急公告</Option>
            <Option value="2">重要公告</Option>
            <Option value="0">普通</Option>
          </Select>
        </FormItem>
        <FormItem label="公告详情" prop="synopsis">
<!--          <div id="editor" style="width: 600px;height: 400px"></div>-->
          <Input v-model="formItem.synopsis" type="textarea" :autosize="{minRows: 2,maxRows: 5}"
                 placeholder="请输入公告详情"></Input>
        </FormItem>
        <FormItem>
          <Button type="primary" @click="handleSubmit('formItem')">确认</Button>
          <Button @click="handleReset('formItem')" style="margin-left: 8px">重置</Button>
        </FormItem>
      </Form>
    </Modal>
    <Modal
        title="已看用户列表"
        v-model="detailTable"
        width="800"
        :footer-hide="true"
        :mask-closable="false"
        class-name="vertical-center-modal">
        <div style="max-height: 600px;  overflow-y: auto;">
          <Table border :columns="detailTa"  :data="detailNu">        
          </Table>
        </div>
    </Modal>
  </div>
</template>
<script>
import {noticeList, noticeAdd, noticeInfo ,noticeUpdate,noticeDel,noticeUn} from '../../api/notice'
import moment from "moment";
import {noticeReadList} from "../../api/operation";
// import E from 'wangeditor'

export default {
  data() {
    return {
      platformColumns: [
        {
          title: '公告标题',
          key: 'title',
          align:'center'
        },
        {
          title: '公告类型',
          key: 'notice_type',
          slot: 'noticeType',
          align:'center'
        },
        // {
        //   title: '公告平台',
        //   key: 'un_name'
        // },
        {
          title: '公告用户类型',
          key: 'user_type',
          slot: 'userType',
          align:'center'
        },
        {
          title: '发送时间',
          key: 'created_at',
          align:'center'
        },
        {
          title: '已看用户数量',
          key: 'quantity_seen',
          slot: 'num',
          align:'center'
        },
        {
          title: '操作',
          slot: 'operation',
          align:'center'
        }
      ],
      data6: [],
      showModel: false,
      formItem: {
        title: '',
        synopsis: '',
        notice_type: '',
        store_id: '',
        id: '',
        user_type:''
      },
      ruleValidate: {
        title: [
          { required: true, message: '请输入公告标题', trigger: 'blur' },
          { type: 'string', min: 3,max:12, message: '公告标题不得少于三个字符超过十二个字符', trigger: 'blur' }
        ],
        synopsis: [
          { required: true, message: '请输入公告详情', trigger: 'blur' }
        ],
        // store_id: [
        //   { required: false, message: '请选择公告平台', trigger: 'change' }
        // ],
        user_type: [
          { required: true, message: '请选择公告用户类型',trigger: 'change' },
        ],
        notice_type: [
          { required: true, message: '请选择公告类型', trigger: 'change' }
        ]
      },
      formTitle: '新增公告',
      detailTable: false,
      detailTa: [
        {
          title: '名称',
          key: 'un_name'
        },
        {
          title: '账户',
          key: 'account_name'
        },
        {
          title: '类型',
          key: 'type'
        },
        {
          title: '时间',
          key: 'created_at'
        },
      ],
      detailNu: [

      ],
      noticePrams: {
        limit: 10,
        page: 1,
        title:'',
        created_at:''
      },
      noticeDetail: {},
      unList:[],
      total:0,
      jurisdictionList:[]
    }
  },
  created() {
    this.getList()
    this.getSelect()
    this.jurisdictionList = this.$store.state.auth
  },
  mounted(){
    // let editor = new E('#editor')
    // editor.create()
    // editor.txt.html(this.content)
    // editor.config.uploadImgShowBase64 = true
    // let eds = document.getElementsByClassName('w-e-text-container')
    // eds[0].style = eds[0].style.cssText + 'height: 800px'
  },
  methods: {
    getSelect(){
      noticeUn().then(res => {
        if (res.code === 200) {
          this.unList = res.data
        }
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    getList() {
      noticeList(this.noticePrams).then(res => {
        if (res.code === 200) {
          this.noticeDetail = res.data
          this.data6 = this.noticeDetail.data
          this.total = res.data.total
        }
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    pageChange(index) {
      this.noticePrams.page = index
      this.getList()
    },
    searchList(){
      this.noticePrams.created_at = moment(this.noticePrams.created_at).format('YYYY-MM-DD')
      if(this.noticePrams.created_at == 'Invalid date'){
        this.noticePrams.created_at = ''
      }
      this.noticePrams.page = 1
      this.getList()
    },
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          if(this.formItem.id){
            noticeUpdate(this.formItem).then(res => {
              if (res.code === 200) {
                this.$Message.success(res.msg)
                this.getList()
                this.reset()
                this.showModel=false
              }
            }).catch(res => {
              this.$Message.error(res.msg)
            })
          }else {
            noticeAdd(this.formItem).then(res => {
              if (res.code === 200) {
                this.$Message.success(res.msg)
                this.getList()
                this.reset()
                this.showModel=false
              }
            }).catch(res => {
              this.$Message.error(res.msg)
            })
          }
        } else {
          this.$Message.error('请输入公告信息');
        }
      })
    },
    handleReset (name) {
      this.$refs[name].resetFields();
    },
    submit() {
      if(this.formItem.id){
        noticeUpdate(this.formItem).then(res => {
          if (res.code === 200) {
            this.$Message.success(res.msg)
            this.getList()
          }
        }).catch(res => {
          this.$Message.error(res.msg)
        })
      }else {
        noticeAdd(this.formItem).then(res => {
          if (res.code === 200) {
            this.$Message.success(res.msg)
            this.getList()
          }
        }).catch(res => {
          this.$Message.error(res.msg)
        })
      }
      this.reset()
    },
    cancel() {
      this.reset()
    },
    show(index, type, id) {
      if (type === 'edit') {
        this.formTitle = '编辑公告信息'
      }
      noticeInfo({id: id}).then(res => {
        if (res.code === 200) {
          this.formItem.title = res.data.title
          this.formItem.visit = res.data.visit
          this.formItem.synopsis = res.data.synopsis
          this.formItem.notice_type = res.data.notice_type
          this.formItem.user_type = res.data.user_type
          this.formItem.id = res.data.id
          this.formItem.sort = res.data.sort
          this.formItem.hide = res.data.hide
        }
      }).catch(res => {
        this.$Message.error(res.msg)
      })
      this.showModel = true
    },
    removeNotice(id) {
      this.$Modal.confirm({
        title: '删除提示',
        content: '请问您是否确认删除此条公告，删除后无法恢复！！！',
        onOk: () => {
          noticeDel({id:id}).then(res=>{
            this.getList()
            this.$Message.success(res.msg)
          }).catch(res=>{
            this.$Message.error(res.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    addNotice(type) {
      if (type === 'add') {
        this.formTitle = '新增公告'
      }
      this.showModel = true
    },
    seeTable(row) {
      noticeReadList({notice_id:row.id}).then(res=>{
        this.detailNu = res.data || []
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
      this.detailTable = true
    },
    reset(){
      this.formItem.title = ''
      this.formItem.synopsis = ''
      this.formItem.notice_type = ''
      this.formItem.store_id = ''
      this.formItem.id = ''
      this.formItem.user_type = ''
    }
  }
}
</script>
<style>
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
 
}

.vertical-center-modal .ivu-modal {
  top: 0;

}

.flex-style {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.modal-form {

}

.form-label {
  padding-right: 12px;
  width: 120px;
  text-align: right;
  font-size: 14px;
  color: #515a6e;
}
</style>
