import request from '@/utils/request'

export function noticeList (data) {
    return request({
        url: '/notice/index',
        method: 'post',
        data
    })
}
export function noticeAdd (data) {
    return request({
        url: '/notice/add',
        method: 'post',
        data
    })
}
export function noticeUpdate (data) {
    return request({
        url: '/notice/update',
        method: 'post',
        data
    })
}
export function noticeInfo (data) {
    return request({
        url: '/notice/info',
        method: 'post',
        data
    })
}
export function noticeDel (data) {
    return request({
        url: '/notice/del',
        method: 'post',
        data
    })
}
export function noticeUn (data) {
    return request({
        url: '/notice/un_list',
        method: 'post',
        data
    })
}